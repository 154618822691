import z from "zod";
import APIFetcher from "./APIFetcher";

export const RuuvitagModelSchema = z
  .object({
    name: z.string().nullable(),
    adopted: z.boolean(),
    created_at: z.string(),
    updated_at: z.string(),
    mac: z.string(),
    id: z.number(),
  })
  .strict();
export type RuuvitagModel = z.infer<typeof RuuvitagModelSchema>;

export const RuuvitagUpdateModelSchema = z
  .object({
    adopted: z.boolean(),
    name: z.string().nullable(),
  })
  .strict();
export type RuuvitagUpdateModel = z.infer<typeof RuuvitagUpdateModelSchema>;

export class RuuvitagsAPI {
  private api: APIFetcher;

  constructor(api: APIFetcher) {
    this.api = api;
  }

  async getRuuvitags(): Promise<RuuvitagModel[]> {
    const response = await this.api.get("api/ruuvitags/");
    return z.array(RuuvitagModelSchema).parse(response);
  }

  async toggleAdoptRuuviTag(
    ruuvitag: RuuvitagModel,
    adopted: boolean
  ): Promise<RuuvitagUpdateModel> {
    const options = {
      body: JSON.stringify({
        name: ruuvitag.name,
        adopted,
      }),
    };
    const response = await this.api.put(
      `api/ruuvitags/${ruuvitag.id}/`,
      options
    );
    return RuuvitagUpdateModelSchema.parse(response);
  }

  async deleteRuuviTag(ruuvitag: RuuvitagModel) {
    return await this.api.delete(`api/ruuvitags/${ruuvitag.id}/`);
  }

  async updateRuuvitagName(ruuvitag: RuuvitagModel, newName: string) {
    const options = {
      body: JSON.stringify({
        name: newName,
      }),
    };
    const response = await this.api.put(
      `api/ruuvitags/${ruuvitag.id}/`,
      options
    );
    return RuuvitagUpdateModelSchema.parse(response);
  }
}
