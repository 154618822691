import React from "react";
import { RouteProps, Navigate, Route, Routes } from "react-router-dom";
import useAuth from "./AuthContext";

export const ProtectedRoute = (props: RouteProps) => {
  const { email } = useAuth();

  if (!email)
    return (
      <>
        <Navigate to="/login" />
      </>
    );

  return (
    <Routes>
      <Route {...props} />
    </Routes>
  );
};
