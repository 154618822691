import React from "react";
import { Link } from "react-router-dom";
import { ChartBarIcon } from "@heroicons/react/24/solid";
import { useClickOutsideListenerRef } from "../../hooks/useClickOutsideListenerRef";

interface SidebarProps {
  open: boolean;
  onClose: () => void;
}
export const Sidebar: React.FC<SidebarProps> = (props) => {
  const ref = useClickOutsideListenerRef(props.onClose);
  return (
    <div
      className={`w-64 min-h-screen absolute md:relative md:translate-x-0 inset-y-0 left-0 transform ${
        props.open ? "" : "-translate-x-full"
      } transition z-10`}
    >
      <div className="h-screen" ref={ref}>
        <div className="h-full bg-gray-800 text-white px-4">
          <div className="flex justify-center w-full py-8">
            <Link
              to="/"
              className="flex items-center space-x-2"
              onClick={() => props.onClose()}
            >
              <img
                className="w-8 h-8 mr-2"
                src="ruuvi_logo.png"
                alt="logo"
              />
              <span className="font-bold">RuuviCloud</span>
            </Link>
          </div>
          <nav>
            <ul>
              <Link to="/ruuvitags" onClick={() => props.onClose()}>
                <li className="block transition duration-200 py-2 px-4 rounded hover:bg-blue-400">
                  RuuviTags
                </li>
              </Link>
              <Link to="/" onClick={() => props.onClose()}>
                <li className="block transition duration-200 py-2 px-4 rounded hover:bg-blue-400">
                  Measurements
                </li>
              </Link>
            </ul>
            <ul>
              <Link to="/apikeys" onClick={() => props.onClose()}>
                <li className="block transition duration-200 py-2 px-4 rounded hover:bg-blue-400">
                  API keys
                </li>
              </Link>
            </ul>
            <ul style={{ marginTop: "50px" }}>
              <Link to="/logout" onClick={() => props.onClose()}>
                <li className="block transition duration-200 py-2 px-4 rounded hover:bg-blue-400">
                  Logout
                </li>
              </Link>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
};
