import { MEASUREMENT_PARAMETERS } from "./measurementParametersConfig";
import dayjs from "dayjs";
import { MeasurementModel } from "../../API/MeasurementsAPI";

/**
 * Types
 */
interface MeasurementData {
  x: number;
  y: number;
}

interface MeasurementSeries {
  name: string;
  data: MeasurementData[];
}

export interface ConfigsForParameters {
  [index: string]: any;
  temperature?: Record<string, MeasurementSeries>;
  humidity?: Record<string, MeasurementSeries>;
  pressure?: Record<string, MeasurementSeries>;
  acceleration_x?: Record<string, MeasurementSeries>;
  acceleration_y?: Record<string, MeasurementSeries>;
  acceleration_z?: Record<string, MeasurementSeries>;
  battery?: Record<string, MeasurementSeries>;
  tx_power?: Record<string, MeasurementSeries>;
  movement_counter?: Record<string, MeasurementSeries>;
  measurement_sequence_number?: Record<string, MeasurementSeries>;
}

interface RuuvitagSeriesData {
  x: number;
  y: number;
}

interface RuuvitagSeries {
  name: string;
  data: RuuvitagSeriesData[];
}

// interface Measurement {
//   [index: string]: any;
//   ruuvitag_name: string;
//   temperature: string;
//   humidity: string;
//   pressure: string;
//   acceleration_x: string;
//   acceleration_y: string;
//   acceleration_z: string;
//   battery: string;
//   tx_power: number;
//   movement_counter: number;
//   measurement_sequence_number: number;
//   time: Date;
// }

/**
 * Functions
 */
export const measurementResponseToHighcharts = (
  data: MeasurementModel[]
): ConfigsForParameters => {
  const configParameters: ConfigsForParameters = {};
  MEASUREMENT_PARAMETERS.forEach((parameter) => {
    const seriesDatas: Record<string, RuuvitagSeries> = {};
    data.forEach((measurement) => {
      const ruuvitagId = String(measurement.ruuvitag_name);

      if (!seriesDatas.hasOwnProperty(ruuvitagId)) {
        seriesDatas[ruuvitagId] = {
          name: String(measurement.ruuvitag_name),
          data: [
            {
              x: dayjs(measurement.time).valueOf(),
              y: Number(
                parseFloat(
                  measurement[parameter.param as keyof MeasurementModel]
                ).toFixed(2)
              ),
            },
          ],
        };
      } else {
        seriesDatas[ruuvitagId].data.push({
          x: dayjs(measurement.time).valueOf(),
          y: Number(
            parseFloat(
              measurement[parameter.param as keyof MeasurementModel]
            ).toFixed(2)
          ),
        });
      }
    });
    configParameters[parameter.param] = seriesDatas;
  });
  return configParameters;
};
