import React, { useState } from "react";
import { CpuChipIcon, TrashIcon } from "@heroicons/react/24/solid";
import dayjs from "dayjs";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Title } from "../Title";
import { api } from "API/API";
import { RuuvitagModel } from "../../API/RuuvitagsAPI";

export const RuuviTags = () => {
  const query = useQuery({
    queryKey: ["ruuvitags"],
    queryFn: async () => await api.ruuvitags.getRuuvitags(),
  });

  return (
    <>
      <Title>RuuviTags</Title>
      <section className="mb-8 rounded-lg shadow-lg container font-mono overflow-x-auto">
        <div className="w-full ">
          <div className="w-full">
            <table className="w-full">
              <thead>
                <tr className="text-md font-semibold tracking-wide text-left text-gray-900 bg-gray-100 uppercase border-b border-gray-600">
                  <th className="px-4 py-3 w-1/2">Name</th>
                  <th className="px-4 py-3 w-1/4">Added</th>
                  <th className="px-4 py-3 w-1/4">Status</th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {!query.data
                  ? null
                  : query.data.map((ruuvitag, index) => (
                      <RuuviTagRow key={index} ruuvitag={ruuvitag} />
                    ))}
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </>
  );
};

interface RuuviTagRowProps {
  ruuvitag: RuuvitagModel;
}

const RuuviTagRow: React.FC<RuuviTagRowProps> = (props: RuuviTagRowProps) => {
  const { name, adopted, created_at, mac } = props.ruuvitag;
  const [isEditingName, setIsEditingName] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const [editedName, setEditedName] = useState(name || "Unnamed");

  const queryClient = useQueryClient();

  const blurInput = (e: any) => {
    if (e.key === "Enter") {
      e.target.blur();
    }
  };

  return (
    <tr className="text-gray-700">
      <td className="px-4 py-3 border ">
        <div className="flex items-center text-sm">
          <div className="relative w-8 h-8 mr-3 rounded-full md:block">
            <CpuChipIcon />
            <div
              className="absolute inset-0 rounded-full shadow-inner"
              aria-hidden="true"
            />
          </div>
          <div>
            <div className="flex justify-start">
              {!isEditingName && (
                <p
                  className="font-semibold text-black"
                  onClick={() => {
                    setIsEditingName(true);
                  }}
                >
                  {editedName}
                </p>
              )}
              {isEditingName && (
                <input
                  type="text"
                  className="font-semibold text-black px-1 py-0"
                  onChange={(event) => {
                    setEditedName(event.target.value);
                  }}
                  value={editedName}
                  autoFocus={true}
                  onBlur={async () => {
                    setIsEditingName(false);
                    await api.ruuvitags.updateRuuvitagName(
                      props.ruuvitag,
                      editedName
                    );
                    await queryClient.invalidateQueries({
                      queryKey: ["ruuvitags"],
                    });
                  }}
                  onKeyDown={blurInput}
                />
              )}
            </div>
            <p className="text-xs text-gray-600">{mac}</p>
          </div>
        </div>
      </td>
      <td className="px-4 py-3 text-sm border">
        {dayjs(created_at).format("DD-MM-YYYY HH:mm")}
      </td>
      <td className="px-4 py-3 text-xs border">
        <div className="flex items-center relative h-8 mr-3">
          {!adopted && (
            <button
              className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded w-24"
              onClick={async () => {
                await api.ruuvitags.toggleAdoptRuuviTag(props.ruuvitag, true);
                await queryClient.invalidateQueries({
                  queryKey: ["ruuvitags"],
                });
              }}
            >
              Adopt
            </button>
          )}
          {adopted && (
            <button
              className="bg-green-100 text-white font-bold py-2 px-4 rounded text-green-700 hover:text-gray-50 hover:bg-red-600 cursor-pointer w-24"
              onMouseOver={() => {
                setIsHovering(true);
              }}
              onMouseOut={() => {
                setIsHovering(false);
              }}
              onClick={async () => {
                await api.ruuvitags.toggleAdoptRuuviTag(props.ruuvitag, false);
                await queryClient.invalidateQueries({
                  queryKey: ["ruuvitags"],
                });
              }}
            >
              {isHovering ? "Unadopt" : "Adopted"}
            </button>
          )}
          <TrashIcon
            className="cursor-pointer hover:text-red-600"
            height={30}
            onClick={async () => {
              await api.ruuvitags.deleteRuuviTag(props.ruuvitag);
              await queryClient.invalidateQueries({
                queryKey: ["ruuvitags"],
              });
            }}
          />
        </div>
      </td>
    </tr>
  );
};
