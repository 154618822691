import React, {useState} from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import {api} from "../../API/API";
import {MEASUREMENT_PARAMETERS} from "./measurementParametersConfig";
import {
  ConfigsForParameters,
  measurementResponseToHighcharts,
} from "./helpers";
import {Title} from "Components/Title";
import {useQuery} from "@tanstack/react-query";
import {MeasurementModel} from "../../API/MeasurementsAPI";

export const Measurements = () => {
  const [chartOptions, setChartOptions] = useState<ConfigsForParameters>({});

  const {isLoading} = useQuery<unknown, unknown, MeasurementModel[]>({
    queryKey: ["measurements"],
    queryFn: () => {
      return api.measurements.getMeasurements();
    },
    onSuccess: (res) => {
      const chartData = measurementResponseToHighcharts(res);
      setChartOptions(chartData);
    },
  });

  return (
    <>
      <Title>Measurements</Title>
      {isLoading &&
      <div className="flex justify-center">
        <div
          className="inline-block h-8 w-8 animate-spin text-gray-50 rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
          role="status">
        <span
          className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
          Loading...
        </span>
        </div>
      </div>
      }
      {!isLoading &&
      <section className="font-mono grid grid-cols-1 lg:grid-cols-2 gap-12">
        {Object.keys(chartOptions).map((param, index) => {
          const paramConfig = MEASUREMENT_PARAMETERS.find(
            (p) => p.param === param
          );
          const series = Object.keys(chartOptions[param]).map(
            (ruuvitagId) => chartOptions[param][ruuvitagId]
          );
          const options = {
            ...paramConfig?.config,
            series,
          };
          return (
            <div key={index} className="rounded-lg shadow-lg bg-blueGray-800">
              <div className="w-full">
                <HighchartsReact highcharts={Highcharts} options={options}/>
              </div>
            </div>
          );
        })}
      </section>
      }
    </>
  );
};
