import z from "zod";
import APIFetcher from "./APIFetcher";

export const ApiKeyModelSchema = z
  .object({
    apikey: z.string(),
    id: z.number(),
    created: z.string(),
    modified: z.string(),
    user: z.number(),
  })
  .strict();

export type ApiKeyModel = z.infer<typeof ApiKeyModelSchema>;

export class ApiKeysAPI {
  private api: APIFetcher;

  constructor(api: APIFetcher) {
    this.api = api;
  }

  async getApiKeys(): Promise<ApiKeyModel[]> {
    const response = await this.api.get(`api/apikeys/`);
    return z.array(ApiKeyModelSchema).parse(response);
  }

  async createApiKey(): Promise<ApiKeyModel> {
    const response = await this.api.post(`api/apikeys/`);
    return ApiKeyModelSchema.parse(response);
  }

  async deleteApiKey(id: number): Promise<void> {
    return await this.api.delete(`api/apikeys/${id}`);
  }

  async regenerateApiKey(id: number): Promise<ApiKeyModel> {
    const response = await this.api.put(`api/apikeys/${id}`);
    return ApiKeyModelSchema.parse(response);
  }
}
