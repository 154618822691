import React from "react";

interface TitleProps {
  children: React.ReactNode;
}
export const Title: React.FC<TitleProps> = (props) => (
  <h1 className="text-3xl md:text-6xl font-normal mt-0 mb-10 text-gray-50">
    {props.children}
  </h1>
);
