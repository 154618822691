import z from "zod";
import APIFetcher from "./APIFetcher";

export const MeasurementModelSchema = z
  .object({
    ruuvitag_name: z.string().nullable(),
    temperature: z.string(),
    humidity: z.string(),
    pressure: z.string(),
    acceleration_x: z.string(),
    acceleration_y: z.string(),
    acceleration_z: z.string(),
    battery: z.number(),
    tx_power: z.number(),
    movement_counter: z.number(),
    measurement_sequence_number: z.number(),
    time: z.any(),
  })
  .strict();

export type MeasurementModel = z.infer<typeof MeasurementModelSchema>;

export class MeasurementsAPI {
  private api: APIFetcher;

  constructor(api: APIFetcher) {
    this.api = api;
  }

  async getMeasurements(): Promise<MeasurementModel[]> {
    const response = await this.api.get("api/measurements/");
    return z.array(MeasurementModelSchema).parse(response);
  }
}
