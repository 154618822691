class APIFetcher {
  async get(url: string): Promise<any> {
    const res = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (res.ok) {
      return await res.json();
    } else {
      throw new Error("API GET error");
    }
  }

  async post(url: string, options?: object): Promise<object> {
    const res = await fetch(url, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      ...options,
    });
    if (res.ok) {
      if (res.headers.get("content-length") === "0") {
        return {};
      }
      return await res.json();
    } else {
      throw new Error("API POST error");
    }
  }

  async put(url: string, options?: object): Promise<any> {
    const res = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      ...options,
    });
    if (res.ok) {
      return await res.json();
    } else {
      throw new Error("API PUT error");
    }
  }

  async delete(url: string, options?: object): Promise<any> {
    const res = await fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      ...options,
    });
    if (res.ok) {
      if (res.status === 204) {
        return res;
      }
      return await res.json();
    } else {
      throw new Error("API DELETE error");
    }
  }
}

export default APIFetcher;
