import APIFetcher from "./APIFetcher";
import { RuuvitagsAPI } from "./RuuvitagsAPI";
import { MeasurementsAPI } from "./MeasurementsAPI";
import { ApiKeysAPI } from "./ApiKeysAPI";
import { AuthAPI } from "./AuthAPI";

export class API {
  private fetcher = new APIFetcher();
  ruuvitags: RuuvitagsAPI;
  measurements: MeasurementsAPI;
  apikeys: ApiKeysAPI;
  auth: AuthAPI;

  constructor() {
    this.ruuvitags = new RuuvitagsAPI(this.fetcher);
    this.measurements = new MeasurementsAPI(this.fetcher);
    this.apikeys = new ApiKeysAPI(this.fetcher);
    this.auth = new AuthAPI(this.fetcher);
  }
}

export const api = new API();
