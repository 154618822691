import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import { api } from "../../API/API";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { AuthModel, UserModel } from "../../API/AuthAPI";

interface AuthContextType {
  email?: string | null;
  loading: boolean;
  error?: any;
  login: (email: string, password: string) => void;
  register: (email: string, password: string) => void;
  logout: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType>({} as AuthContextType);

export function AuthProvider({
  children,
}: {
  children: ReactNode;
}): JSX.Element {
  const [email, setEmail] = useState<string | null>();
  const [error, setError] = useState<any>();
  const [loading] = useState<boolean>(false);
  const [loadingInitial, setLoadingInitial] = useState<boolean>(true);
  const navigate = useNavigate();
  const location = useLocation();

  const registerMutation = useMutation<
    AuthModel,
    unknown,
    Record<string, string>,
    void
  >({
    mutationKey: ["register"],
    mutationFn: ({ email, password }) => {
      return api.auth.register(email, password);
    },
    onSuccess: (data) => {
      setEmail(data.user.email);
      navigate("/");
    },
  });

  const loginMutation = useMutation<
    AuthModel,
    unknown,
    Record<string, string>,
    void
  >({
    mutationKey: ["login"],
    mutationFn: ({ email, password }) => {
      return api.auth.login(email, password);
    },
    onSuccess: (res) => {
      setEmail(res.user.email);
      navigate("/");
    },
  });

  const logoutMutation = useMutation<unknown, unknown, void, unknown>({
    mutationKey: ["logout"],
    mutationFn: () => {
      return api.auth.logout();
    },
    onSuccess: () => {
      setEmail(null);
      navigate("/login");
    },
  });

  useQuery<unknown, unknown, UserModel>({
    queryKey: ["me"],
    queryFn: () => {
      return api.auth.me();
    },
    onSuccess: (res) => {
      if (res.email === null) {
        navigate("/login");
      }
      setEmail(res.email);
      setLoadingInitial(false);
    },
  });

  // If we change page, reset the error state.
  useEffect(() => {
    if (error) setError(null);
    // eslint-disable-next-line
  }, [location.pathname]);

  async function login(email: string, password: string) {
    loginMutation.mutate({ email, password });
  }

  // Sends sign up details to the server. On success should:
  //   - Add user email to state to clarify user is authed
  //   - Receive access & refresh tokens in the cookie
  async function register(email: string, password: string) {
    // setLoading(true);
    registerMutation.mutate({ email, password });
    // setLoading(false);
  }

  // Call the logout endpoint and then remove the user
  // from the state.
  async function logout() {
    logoutMutation.mutate();
    setEmail(null);
  }

  // Make the provider update only when it should.
  // We only want to force re-renders if the user,
  // loading or error states change.
  //
  // Whenever the `value` passed into a provider changes,
  // the whole tree under the provider re-renders, and
  // that can be very costly! Even in this case, where
  // you only get re-renders when logging in and out
  // we want to keep things very performant.
  const memoedValue = useMemo(
    () => ({
      email,
      loading,
      error,
      login,
      register,
      logout,
    }),
    // eslint-disable-next-line
    [email, loading, error]
  );

  // We only want to render the underlying app after we
  // assert for the presence of a current user.
  return (
    <>
      {loadingInitial ? null : (
        <AuthContext.Provider value={memoedValue}>
          {children}
        </AuthContext.Provider>
      )}
    </>
  );
}

// Let's only export the `useAuth` hook instead of the context.
// We only want to use the hook directly and never the context component.
export default function useAuth() {
  return useContext(AuthContext);
}
