import React, { useEffect } from "react";

import useAuth from "./AuthContext";
import { Navigate } from "react-router-dom";

export const Logout = () => {
  const auth = useAuth();

  useEffect(() => {
    auth.logout();
    // eslint-disable-next-line
  }, []);

  if (auth.email) {
    return <div>Logging out...</div>;
  } else {
    return <Navigate to="/" />;
  }
};
