import React from "react";
import { ChartBarIcon, Bars3Icon } from "@heroicons/react/24/solid";
import { Link } from "react-router-dom";

interface SidebarMobileProps {
  toggleSidebarMenu: () => void;
}

export const SidebarMobile: React.FC<SidebarMobileProps> = (props) => {
  return (
    <div className="py-4 px-6 bg-gray-800 text-gray-50 flex justify-between md:hidden">
      <Link to="/" className="flex items-center space-x-2">
        <img
          className="w-8 h-8 mr-2"
          src="ruuvi_logo.png"
          alt="logo"
        />
        <span className="font-bold">RuuviCloud</span>
      </Link>
      <button
        className="focus:outline-none focus:bg-gray-900"
        onClick={() => props.toggleSidebarMenu()}
      >
        <Bars3Icon height={24} className="" />
      </button>
    </div>
  );
};
