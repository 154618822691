import z from "zod";
import APIFetcher from "./APIFetcher";

export const UserModelSchema = z
  .object({
    created_at: z.string().optional(),
    id: z.number().nullable(),
    is_active: z.boolean(),
    updated_at: z.string().optional(),
    email: z.string().nullable(),
  })
  .strict();
export type UserModel = z.infer<typeof UserModelSchema>;

export const AuthModelSchema = z
  .object({
    user: UserModelSchema,
    // refresh: z.string(),
    // access: z.string(),
  })
  .strict();
export type AuthModel = z.infer<typeof AuthModelSchema>;

export class AuthAPI {
  private api: APIFetcher;

  constructor(api: APIFetcher) {
    this.api = api;
  }

  async me(): Promise<UserModel> {
    const response = await this.api.post(`api/auth/me/`);
    return UserModelSchema.parse(response);
  }

  async register(email: string, password: string): Promise<AuthModel> {
    const response = await this.api.post("api/auth/register/", {
      body: JSON.stringify({ email, password }),
    });
    return AuthModelSchema.parse(response);
  }

  async login(email: string, password: string): Promise<AuthModel> {
    const response = await this.api.post("api/auth/login/", {
      body: JSON.stringify({
        email,
        password,
      }),
    });
    return AuthModelSchema.parse(response);
  }

  async logout(): Promise<object> {
    return await this.api.post("api/auth/logout/");
  }
}
