import React from "react";
import { Routes, Route } from "react-router-dom";

import { RuuviTags } from "Components/RuuviTags/RuuviTags";
import { Measurements } from "Components/Measurements/Measurements";
import { ProtectedRoute } from "../Auth/ProtectedRoute";
import { Logout } from "../Auth/Logout";
import { ApiKeys } from "../ApiKeys/ApiKeys";

export const Main = () => {
  return (
    <div className="flex-1 min-h-screen overflow-y-auto">
      <div className="flex h-screen">
        <main
          role="main"
          className="flex-1 p-6 md:p-10 bg-blueGray-700 overflow-y-auto"
        >
          <Routes>
            <Route path="/logout" element={<Logout />} />
            <Route
              path="/ruuvitags/*"
              element={<ProtectedRoute path="/" element={<RuuviTags />} />}
            />
            <Route
              path="/apikeys/*"
              element={<ProtectedRoute path="/" element={<ApiKeys />} />}
            />
            <Route
              path="/*"
              element={<ProtectedRoute path="/" element={<Measurements />} />}
            />
          </Routes>
        </main>
      </div>
    </div>
  );
};
