import React from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Sidebar } from "./Components/Sidebar/Sidebar";
import { Main } from "./Components/Main/Main";
import { SidebarMobile } from "./Components/Sidebar/SidebarMobile";
import { Login } from "./Components/Auth/Login";
import { Register } from "./Components/Auth/Register";
import { AuthProvider } from "./Components/Auth/AuthContext";
// import WrongSwitch from "react-switch";

// const Switch = WrongSwitch as any;

const queryClient = new QueryClient();

function App() {
  const [mobileSidebarOpen, setMobileSidebarOpen] =
    React.useState<boolean>(false);

  const toggleSidebarMenu = () => {
    setMobileSidebarOpen(!mobileSidebarOpen);
  };

  const closeSidebarMenu = () => {
    if (mobileSidebarOpen) {
      setMobileSidebarOpen(false);
    }
  };

  return (
    <>
      <Router>
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            {/*<div className="relative min-h-screen md:flex">*/}
            <div className="relative min-h-screen">
              <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route
                  path="/*"
                  element={
                    <>
                      <div className="md:flex">
                        <SidebarMobile toggleSidebarMenu={toggleSidebarMenu} />
                        <Sidebar
                          open={mobileSidebarOpen}
                          onClose={closeSidebarMenu}
                        />
                        <Main />
                      </div>
                    </>
                  }
                />
              </Routes>
            </div>
          </AuthProvider>
          <ReactQueryDevtools initialIsOpen={true} />
        </QueryClientProvider>
      </Router>
    </>
  );
}

export default App;
