import { Options } from "highcharts";
const merge = require("lodash.merge");

const defaults = {
  accessibility: {
    enabled: false,
  },
  colors: [
    "#4465f5",
    "#3cd865",
    "#a895fe",
    "#7798BF",
    "#aaeeee",
    "#ff0066",
    "#eeaaee",
    "#55BF3B",
    "#DF5353",
    "#7798BF",
    "#aaeeee",
  ],
  chart: {
    zoomType: "x",
    backgroundColor: "rgba(0,0,0,0)",
  },
  title: {
    style: {
      color: "#fff",
    },
  },
  time: {
    timezoneOffset: new Date().getTimezoneOffset(),
  },

  credits: {
    enabled: false,
  },
  plotOptions: {
    series: {
      turboThreshold: 0,
      marker: {
        enabled: false,
      },
    },
  },
  xAxis: {
    type: "datetime",
    labels: {
      style: { color: "#aaa" },
    },
  },
  yAxis: {
    title: {
      style: { color: "#aaa" },
    },
    labels: {
      style: { color: "#aaa" },
    },
    gridLineColor: "rgba(255,255,255,0.1)",
  },
  legend: {
    itemStyle: {
      color: "#E0E0E3",
    },
    itemHoverStyle: {
      color: "#FFF",
    },
    itemHiddenStyle: {
      color: "#606063",
    },
  },
};

export const temperatureConfig: Options = merge({}, defaults, {
  title: {
    text: "Temperature",
  },
  yAxis: {
    title: {
      text: "C",
    },
  },
});

export const humidityConfig: Options = merge({}, defaults, {
  title: {
    text: "Humidity",
  },
  yAxis: {
    title: {
      text: "%",
    },
  },
});

export const pressureConfig: Options = merge({}, defaults, {
  title: {
    text: "Pressure",
  },
  yAxis: {
    title: {
      text: "Pa",
    },
  },
});

export const accelerationXConfig: Options = merge({}, defaults, {
  title: {
    text: "Acceleration X",
  },
  yAxis: {
    title: {
      text: "mG",
    },
  },
});

export const accelerationYConfig: Options = merge({}, defaults, {
  title: {
    text: "Acceleration Y",
  },
  yAxis: {
    title: {
      text: "mG",
    },
  },
});

export const accelerationZConfig: Options = merge({}, defaults, {
  title: {
    text: "Acceleration Z",
  },
  yAxis: {
    title: {
      text: "mG",
    },
  },
});

export const batteryConfig: Options = merge({}, defaults, {
  title: {
    text: "Battery",
  },
  yAxis: {
    title: {
      text: "V",
    },
  },
});

export const txPowerConfig: Options = merge({}, defaults, {
  title: {
    text: "Tx Power",
  },
  yAxis: {
    title: {
      text: "dBm",
    },
  },
});

export const movementCounterConfig: Options = merge({}, defaults, {
  title: {
    text: "Movement counter",
  },
  yAxis: {
    title: {
      text: "pcs",
    },
  },
});

export const measurementSequenceNumberConfig: Options = merge({}, defaults, {
  title: {
    text: "Measurement sequence number",
  },
  yAxis: {
    title: {
      text: "pcs",
    },
  },
});

export const solidGauge: any = merge({}, defaults, {
  chart: {
    type: "solidgauge",
  },
  title: { text: "Humidor" },
  pane: {
    center: ["50%", "50%"],
    size: "100%",
    startAngle: -90,
    endAngle: 90,
    background: {
      innerRadius: "60%",
      outerRadius: "100%",
      shape: "arc",
    },
  },
  exporting: {
    enabled: false,
  },

  tooltip: {
    enabled: false,
  },

  // the value axis
  yAxis: {
    stops: [
      [0.1, "#DF5353"],
      [0.65, "#DDDF0D"],
      [0.7, "#55BF3B"],
    ],
    lineWidth: 0,
    tickWidth: 0,
    minorTickInterval: null,
    tickPositions: [0, 100],
    title: {
      y: -70,
    },
    labels: {
      y: 16,
    },
    min: 0,
    max: 100,
  },
  plotOptions: {
    solidgauge: {
      dataLabels: {
        y: 5,
        borderWidth: 0,
        useHTML: true,
      },
    },
  },
  series: [
    {
      name: "Speed",
      data: [0],
      dataLabels: {
        format:
          '<div style="text-align:center">' +
          '<span style="font-size:20px;color:white">{y}</span><br/>' +
          '<span style="font-size:12px;opacity:0.4;color:white">C</span>' +
          "</div>",
      },
    },
  ],
});
