import React from "react";
import { KeyIcon, ArrowPathIcon, TrashIcon } from "@heroicons/react/24/solid";
import dayjs from "dayjs";
import { Title } from "../Title";
import { api } from "API/API";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

interface ApiKeyResponse {
  apikey: string;
  id: number;
  created: string;
  modified: string;
}

export const ApiKeys = () => {
  const queryClient = useQueryClient();

  const getApiKeysQuery = useQuery({
    queryKey: ["apiKeys"],
    queryFn: async () => await api.apikeys.getApiKeys(),
  });

  const createApiKeyMutation = useMutation({
    mutationKey: ["createApiKey"],
    mutationFn: () => {
      return api.apikeys.createApiKey();
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["apiKeys"]);
    },
  });

  const deleteApiKeyMutation = useMutation<
    unknown,
    unknown,
    Record<string, number>,
    void
  >({
    mutationKey: ["deleteApiKey"],
    mutationFn: ({ id }) => {
      return api.apikeys.deleteApiKey(id);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["apiKeys"]);
    },
  });

  const regenerateApiKeyMutation = useMutation<
    unknown,
    unknown,
    Record<string, number>,
    void
  >({
    mutationKey: ["regenerateApiKey"],
    mutationFn: ({ id }) => {
      return api.apikeys.regenerateApiKey(id);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["apiKeys"]);
    },
  });

  return (
    <>
      <Title>API keys</Title>
      <button
        className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mb-8"
        onClick={async () => {
          await createApiKeyMutation.mutate();
        }}
      >
        Create new API key
      </button>
      <section className="mb-8 rounded-lg shadow-lg container font-mono overflow-x-auto">
        <div className="w-full ">
          <div className="w-full">
            <table className="w-full">
              <thead>
                <tr className="text-md font-semibold tracking-wide text-left text-gray-900 bg-gray-100 uppercase border-b border-gray-600">
                  <th className="px-4 py-3 w-1/2">API key</th>
                  <th className="px-4 py-3 w-1/4">Created</th>
                  <th className="px-4 py-3 w-1/4">Modified</th>
                  <th className="px-4 py-3 w-1/4">Actions</th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {!getApiKeysQuery.data
                  ? null
                  : getApiKeysQuery.data.map((apikey, index) => (
                      <ApiKeyRow
                        key={index}
                        apikey={apikey}
                        deleteApiKey={async () => {
                          await deleteApiKeyMutation.mutate({
                            id: apikey.id,
                          });
                        }}
                        regenerateApiKey={async () => {
                          await regenerateApiKeyMutation.mutate({
                            id: apikey.id,
                          });
                        }}
                      />
                    ))}
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </>
  );
};

interface ApiKeyRowProps {
  apikey: ApiKeyResponse;
  deleteApiKey: (id: number) => void;
  regenerateApiKey: (id: number) => void;
}

const ApiKeyRow: React.FC<ApiKeyRowProps> = (props: ApiKeyRowProps) => {
  const { apikey, created, modified, id } = props.apikey;

  return (
    <tr className="text-gray-700">
      <td className="px-4 py-3 border ">
        <div className="flex items-center text-sm">
          <div className="relative w-8 h-8 mr-3 rounded-full md:block">
            <KeyIcon />
            <div
              className="absolute inset-0 rounded-full shadow-inner"
              aria-hidden="true"
            />
          </div>
          <div>
            <p className="text-sm text-gray-600">{apikey}</p>
          </div>
        </div>
      </td>
      <td className="px-4 py-3 text-sm border">
        {dayjs(created).format("DD.MM.YYYY HH:mm")}
      </td>
      <td className="px-4 py-3 text-sm border">
        {dayjs(modified).format("DD.MM.YYYY HH:mm")}
      </td>
      <td className="px-4 py-3 text-sm border">
        <div className="flex items-center relative w-16 h-8 mr-3">
          <ArrowPathIcon onClick={() => props.regenerateApiKey(id)} />
          <TrashIcon onClick={() => props.deleteApiKey(id)} />
        </div>
      </td>
    </tr>
  );
};
