import {
  accelerationXConfig,
  accelerationYConfig,
  accelerationZConfig,
  batteryConfig,
  humidityConfig,
  measurementSequenceNumberConfig,
  movementCounterConfig,
  pressureConfig,
  temperatureConfig,
  txPowerConfig,
} from "./chartConfig";

interface MeasurementParametersRecord {
  param: string;
  config: object;
}

export const MEASUREMENT_PARAMETERS: MeasurementParametersRecord[] = [
  {
    param: "temperature",
    config: temperatureConfig,
  },
  {
    param: "humidity",
    config: humidityConfig,
  },
  {
    param: "pressure",
    config: pressureConfig,
  },
  {
    param: "acceleration_x",
    config: accelerationXConfig,
  },
  {
    param: "acceleration_y",
    config: accelerationYConfig,
  },
  {
    param: "acceleration_z",
    config: accelerationZConfig,
  },
  {
    param: "battery",
    config: batteryConfig,
  },
  {
    param: "tx_power",
    config: txPowerConfig,
  },
  {
    param: "movement_counter",
    config: movementCounterConfig,
  },
  {
    param: "measurement_sequence_number",
    config: measurementSequenceNumberConfig,
  },
];
